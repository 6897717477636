import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorHandler, HandleError } from '../../services/http-error-handler.service';
import { environment } from '../../../environments/environment';
import { ClientComments } from '../models/clientComments';

@Injectable({
  providedIn: 'root'
})
export class ClientsServiceComments {

  private apiUrl = `${environment.apiUrl}/client`;
  private handleError: HandleError;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  redirectUrl: string;

  constructor(
    private http: HttpClient,
    private httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = this.httpErrorHandler.createHandleError('ClientsService')
  }

  /**getProducts(): Observable<Product[]> {
    return this.http.get<Product[]>(`${this.apiUrl}`)
    .pipe(
      catchError(this.handleError('getProducts', []))
    )
  }**/
  //for pagination
  getClients(offset: number, limit: number, filter?: any) {
    return this.http.get<ClientComments>(`${this.apiUrl}/${offset}/${limit}/${JSON.stringify(filter)}`)
    .pipe(
      catchError(this.handleError('getClients', null))
    )
  }
  getClient(id: number) {
    return this.http.get<ClientComments>(`${this.apiUrl}/${id}`)
    .pipe(
      catchError(this.handleError('getClient', null))
    )
  }

  addComment(client: ClientComments) {
    return this.http.post<ClientComments>(`${this.apiUrl}/comments/add`, client, this.httpOptions)
    .pipe(
      catchError(this.handleError('addComment', null))
    )
  }

  updateComment(clientComment: ClientComments) {
    return this.http.put<ClientComments>(`${this.apiUrl}/comments/update`, clientComment, this.httpOptions)
    .pipe(
      catchError(this.handleError('updateComment', null))
    )
  }
  addRecalls(client: ClientComments) {
    return this.http.post<ClientComments>(`${this.apiUrl}/recalls/addrecall`, client, this.httpOptions)
    .pipe(
      catchError(this.handleError('updateRecalls', null))
    )
  }
  
  deleteRecalls(id: number) {
    return this.http.delete(`${this.apiUrl}/recalls/delrecall/${id}`)
    .pipe(
      catchError(this.handleError('deleteRecalls', null))
    )
  }
  disableTask(id: number) {
    return this.http.patch(`${this.apiUrl}/recalls/disabletask/${id}`,"", this.httpOptions)
    .pipe(
      catchError(this.handleError('disableTask', null))
    )
  }
  deleteComment(id: number) {
    return this.http.delete(`${this.apiUrl}/comments/delete/${id}`)
    .pipe(
      catchError(this.handleError('deleteComment', null))
    )
  }

}


